import { template as template_571f2a7498ee4aa28b615c022f315843 } from "@ember/template-compiler";
const SidebarSectionMessage = template_571f2a7498ee4aa28b615c022f315843(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
