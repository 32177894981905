import { template as template_1b38616ddf924f8c9bd51f2c67be4dc0 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_1b38616ddf924f8c9bd51f2c67be4dc0(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
