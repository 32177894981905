import { template as template_e6b229897fa841df9a0df43a17308ab6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e6b229897fa841df9a0df43a17308ab6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
