import { template as template_391dec2c852c49439f1208c6dec532c1 } from "@ember/template-compiler";
const FKText = template_391dec2c852c49439f1208c6dec532c1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
